class EasybuilderTransPandaFrontend extends elementorModules.ViewModule {
  bindEvents() {
    jQuery(window).on('elementor/frontend/init', this.addElementsHandlers);
  }
  addElementsHandlers() {
    const { default: stSwiperHandler } = require( './handlers/stswiper' );
    const { default: brandSliderHandler } = require( './handlers/brandslider' );
    const { default: stInstagramHandler } = require( './handlers/stinstagram' );
    const { default: productSliderHandler } = require( './handlers/product-slider' );
    const { default: productSliderTabHandler } = require( './handlers/product-slider-tab' );
    const { default: searchHandler } = require( './handlers/search' );
    const { default: stBlogSliderHandler } = require( './handlers/stblog-slider' );
    const { default: categorySliderHandler } = require( './handlers/categoryslider' );
    const { default: stTextSliderHandler } = require( './handlers/sttextslider' );
    const { default: stblogSliderHandler } = require( './handlers/stblog_slider' );

    const handlers = {
      'stswiper.default': stSwiperHandler,
      'brandslider.default': brandSliderHandler,
      'stinstagram.default': stInstagramHandler,
      'product_slider.default': productSliderHandler,
      'product_slider_tab.default': productSliderTabHandler,
      'search.default': searchHandler,
      'stblog_slider.default': stBlogSliderHandler,
      'categoryslider.default': categorySliderHandler,
      'sttextslider.default': stTextSliderHandler,
      'stblog_slider.default': stblogSliderHandler,
    };
    $.each( handlers, function( elementName, funcCallback ) {
      elementorFrontend.hooks.addAction( 'frontend/element_ready/' + elementName, funcCallback );
    } );
  }

}

window.easybuilderTransPandaFrontend = new EasybuilderTransPandaFrontend();
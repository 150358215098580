class SttextsliderHandler extends elementorModules.frontend.handlers.Base {
  getDefaultSettings() {
    return {
      selectors: {
        slider: '.swiper-container',
        slide: '.swiper-slide',
      },
    };
  }

  getSlidesCount() {
    return this.elements.$slides.length;
  }
  getDefaultElements() {
    var elements = {
      $swiperContainer: this.$element.find(this.getSettings('selectors').slider)
    };
    elements.$slides = elements.$swiperContainer.find(this.getSettings('selectors').slide);
    return elements;
  }
  onInit() {
      elementorModules.frontend.handlers.Base.prototype.onInit.apply(this, arguments);
      this.initSlider();
  }

  initSlider() {
    var settings = this.getSettings(),
        elementSettings = this.getElementSettings();

    if (!this.elements.$swiperContainer.length) {
      return;
    }

    this.swiper = new Swiper(this.elements.$swiperContainer[0], this.getSwiperOptions());
  }

  getInitialSlide() {
    const editSettings = this.getEditSettings();

    return editSettings.activeItemIndex ? editSettings.activeItemIndex - 1 : 0;
  }

  getSwiperOptions() {
    var elementSettings = this.getElementSettings();
    elementSettings.pro_per_fullwidth = elementSettings.pro_per_fullwidth ? parseInt( elementSettings.pro_per_fullwidth, 10 ) : 0;
    elementSettings.pro_per_extralarge = elementSettings.pro_per_extralarge ? parseInt( elementSettings.pro_per_extralarge, 10 ) : 0;
    elementSettings.pro_per_large = elementSettings.pro_per_large ? parseInt( elementSettings.pro_per_large, 10 ) : 0;
    elementSettings.pro_per = elementSettings.pro_per ? parseInt( elementSettings.pro_per, 10 ) : 1;
    elementSettings.pro_per_tablet = elementSettings.pro_per_tablet ? parseInt( elementSettings.pro_per_tablet, 10 ) : 1;
    elementSettings.pro_per_mobile = elementSettings.pro_per_mobile ? parseInt( elementSettings.pro_per_mobile, 10 ) : 1;
    elementSettings.pro_per_small = elementSettings.pro_per_small ? parseInt( elementSettings.pro_per_small, 10 ) : 1;

    var swiperOptions = {
      lazy:{
        loadPrevNext:true,
        loadPrevNextAmount: 1,
        loadOnTransitionStart: true
      },
      speed: elementSettings.slider_a_speed ? elementSettings.slider_a_speed : 400,
      loop: elementSettings.rewind_nav=='1' && this.getSlidesCount()>1,
      // grabCursor: ,
      autoHeight : elementSettings.auto_height=='1',
      slidesPerView : 1,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      initialSlide: this.getInitialSlide(),
      on: {
        init: function (swiper) {
          if (elementSettings.direction_nav!='0'){
            if($(swiper.slides).length==$(swiper.slides).filter('.swiper-slide-visible').length)
            {
                $(swiper.params.navigation.nextEl).hide();
                $(swiper.params.navigation.prevEl).hide();
            }
            else
            {
                $(swiper.params.navigation.nextEl).show();
                $(swiper.params.navigation.prevEl).show();
            }
          }
        },
      },
      inviewwatcher:true,
      spaceBetween: elementSettings.spacing_between.size ? parseInt(elementSettings.spacing_between.size, 10) : 0,
      centeredSlides: elementSettings.centered_slides,
    };

    if(elementSettings.slides_per_view_auto){
      swiperOptions.slidesPerView = 'auto';
      if(elementSettings.rewind_nav=='1')
        swiperOptions.loopedSlides = this.getSlidesCount();
    }else{
      swiperOptions = jQuery.extend(swiperOptions, {
        slidesPerView: elementSettings.pro_per_small,
        slidesPerGroup: elementSettings.pro_per_small,
        breakpoints: {
              992: {slidesPerView: elementSettings.pro_per, slidesPerGroup: elementSettings.pro_per},
              768: {slidesPerView: elementSettings.pro_per_tablet, slidesPerGroup: elementSettings.pro_per_tablet},
              480: {slidesPerView: elementSettings.pro_per_mobile, slidesPerGroup: elementSettings.pro_per_mobile},
          },
      });
      if(elementSettings.pro_per_fullwidth)
        swiperOptions = jQuery.extend(swiperOptions.breakpoints, {
                1600: {slidesPerView: elementSettings.pro_per_fullwidth, slidesPerGroup: elementSettings.pro_per_fullwidth},
        });
      if(elementSettings.pro_per_extralarge)
        swiperOptions = jQuery.extend(swiperOptions.breakpoints, {
                1440: {slidesPerView: elementSettings.pro_per_extralarge, slidesPerGroup: elementSettings.pro_per_extralarge},
        });
      if(elementSettings.pro_per_large)
        swiperOptions = jQuery.extend(swiperOptions.breakpoints, {
                1200: {slidesPerView: elementSettings.pro_per_large, slidesPerGroup: elementSettings.pro_per_large},
        });
    }
    if(elementSettings.slider_slideshow!='0'){
      swiperOptions.autoplay = {
        delay: elementSettings.slider_s_speed ? elementSettings.slider_s_speed : 7000,
        stopOnLastSlide:elementSettings.slider_slideshow=='2',
        disableOnInteraction: elementSettings.slider_pause_on_hover=='1'
      };
    }
    
    if (elementSettings.direction_nav!='0'){
      swiperOptions.navigation = {
        nextEl: '.st-swiper-'+this.getID()+' .swiper-button-next',
        prevEl: '.st-swiper-'+this.getID()+' .swiper-button-prev'
      };
    }
    if (elementSettings.control_nav!='0'){
      swiperOptions.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        type: elementSettings.control_nav=='2' || elementSettings.control_nav=='4' ? 'bullets' : (elementSettings.control_nav=='3' ? 'progress' : 'bullets' ) //A bug of swiper, this should be 'custom' when nav==2
      }
      if (elementSettings.control_nav=='2')
          swiperOptions.pagination.renderBullet = function (index, className) {
              return '<span class="' + className + '">' + (index + 1) + '</span>';
          };
      else if (elementSettings.control_nav=='4')
          swiperOptions.pagination.renderBullet = function (index, className) {
              return '<span class="' + className + '"><span></span></span>';
          };
    }
    return swiperOptions;
  }
  
  onEditSettingsChange(propertyName) {
    if (1 >= this.getSlidesCount()) {
      return;
    }
    if ('activeItemIndex' === propertyName) {
      this.swiper.slideToLoop(this.getEditSettings('activeItemIndex') - 1);
    }
  }
}

export default ( $scope ) => {
  elementorFrontend.elementsHandler.addHandler( SttextsliderHandler, { $element: $scope } );
};

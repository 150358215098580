export default class SliderHandlerBase extends elementorModules.frontend.handlers.Base {
	getDefaultSettings() {
		return {
			selectors: {
				slider: '.products_sldier_swiper',
			},
		};
	}

	onInit() {
	    elementorModules.frontend.handlers.Base.prototype.onInit.apply(this, arguments);
	    if( this.isEdit ) {
		    switch(this.getElementSettings( 'display_as_grid' )){
		    	case '1':
		    	case '2':
		    		elementor.$preview[ 0 ].contentWindow.prestashop.prolazy();
		    	break;
		    	case '0':
					elementor.$preview[ 0 ].contentWindow.prestashop.emit('updateStSlider');
		    	break;
		    	default:
		    	break;
		    }
		}
	}
	onElementChange( propertyName ) {
		const changeableProperties = this.getChangeableProperties();
		if ( changeableProperties[propertyName] ) {
			this.updateSwiperOption( propertyName );
		}
	}


	getChangeableProperties() {
		return {
			slider_slideshow: 'autoplay',
			slider_pause_on_hover: 'disableOnInteraction',
			slider_s_speed: 'delay',
			slider_a_speed: 'speed',
			spacing_between: 'spaceBetween',
			rewind_nav: 'loop',
		};
	}
	updateSwiperOption( propertyName, selector ){
		const elementSettings = this.getElementSettings(),
			newSettingValue = elementSettings[ propertyName ],
			changeableProperties = this.getChangeableProperties();
		if(elementSettings.display_as_grid!='0')
			return false;
		let propertyToUpdate = changeableProperties[ propertyName ],
			valueToUpdate = newSettingValue;

		// Handle special cases where the value to update is not the value that the Swiper library accepts
		switch ( propertyName ) {
			case 'spacing_between':
				valueToUpdate = newSettingValue.size || 0;
				break;
			case 'slider_slideshow':
				if ( newSettingValue!='0' ) {
					valueToUpdate = {
						delay: elementSettings.slider_s_speed,
						stopOnLastSlide: elementSettings.slider_slideshow=='2',
						disableOnInteraction: '1' == elementSettings.slider_pause_on_hover,
					};
				} else {
					valueToUpdate = false;
				}
				break;
			case 'slider_s_speed':
				propertyToUpdate = 'autoplay';
				valueToUpdate = {
					delay: newSettingValue,
					stopOnLastSlide: elementSettings.slider_slideshow=='2',
					disableOnInteraction: '1' == elementSettings.slider_pause_on_hover,
				};
				break;
			case 'slider_pause_on_hover':
				propertyToUpdate = 'autoplay';
				valueToUpdate = {
					delay: elementSettings.slider_s_speed,
					stopOnLastSlide: elementSettings.slider_slideshow=='2',
					disableOnInteraction: '1' == newSettingValue,
				};
				break;
			case 'rewind_nav':
				valueToUpdate = '1' == newSettingValue;
				break;
		}

		const slider = this.$element.find( this.getSettings( 'selectors' ).slider );
		if(slider.length && typeof slider[0].swiper !== 'undefined'){
			slider[0].swiper.params[ propertyToUpdate ] = valueToUpdate;
			slider[0].swiper.update();
			if(propertyName=='slider_slideshow'){
				if(valueToUpdate)
					slider[0].swiper.autoplay.start();
		      	else
			        slider[0].swiper.autoplay.stop();
			}		        
		}
	}

}

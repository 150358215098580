import SliderBase from './base-slider';
class ProductSliderTabHandler extends SliderBase {
	getDefaultSettings() {
		return {
			selectors: {
				slider: '.tab-pane.active .products_sldier_swiper',
			},
		};
	}
}

export default ( $scope ) => {
	elementorFrontend.elementsHandler.addHandler( ProductSliderTabHandler, { $element: $scope } );
};

class searchHandler extends elementorModules.frontend.handlers.Base {
  onInit() {
      elementorModules.frontend.handlers.Base.prototype.onInit.apply(this, arguments);
      if( this.isEdit ) {
	      if(typeof(elementor.$preview[ 0 ].contentWindow['quick_search_as_init'])!='undefined')
	            elementor.$preview[ 0 ].contentWindow['quick_search_as_init']();
	    }
  }
}

export default ( $scope ) => {
  elementorFrontend.elementsHandler.addHandler( searchHandler, { $element: $scope } );
};
